<template>
  <div class="container mt-4">
    <b-col>
      <b-row>
        <b-col class="d-flex align-items-center">
          <BackButton />
          <h5 class="ml-2 mb-0">
            {{ translations.updates.page_detail_breadcrumb }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card class="border-0" :header="translations.updates.page_detail_title" body-class="px-0" header-class="border-0">
            <div class="col-12">
              <div class="row mb-5">
                <div class="col-4">
                  <b>{{ translations.updates.table.request_id }}</b>
                  <p class="detail-item">{{ details.id }}</p>
                </div>
                <div class="col-4">
                  <b>{{ translations.updates.table.update_and_regenerate_statements }}</b>
                  <p class="detail-item">
                    {{ formatBoolean(details.refresh_insurance_info) }} / {{ formatBoolean(details.regenerate_statements) }}
                  </p>
                </div>
                <div class="col-4">
                  <b>{{ translations.updates.table.client_name }}</b>
                  <p class="detail-item">{{ details.client_name }}</p>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-4">
                  <b>{{ translations.updates.table.status }}</b>
                  <p>
                    <b-badge :variant="formatStatusLabel(details.status)">
                      <p class="detail-item badge-status text-capitalize m-0">{{ details.status }}</p>
                    </b-badge>
                  </p>
                </div>
                <div class="col-4">
                  <b>{{ translations.updates.table.date_from }}</b>
                  <p class="detail-item">{{ formatDate(details.statement_start_date) }}</p>
                </div>
                <div class="col-4">
                  <b>{{ translations.updates.table.created_at }}</b>
                  <p class="detail-item">{{ formatDatetime(details.created_at) }}</p>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-4">
                  <b>{{ translations.updates.table.completed_at }}</b>
                  <p class="detail-item">{{ formatDatetime(details.completed_at) }}</p>
                </div>
                <div class="col-4">
                  <b>{{ translations.updates.table.created_by }}</b>
                  <p class="detail-item">{{ details.user_email }}</p>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <b-card
      class="border-0 mb-2 mt-3"
      body-class="px-0"
      header-class="border-0">
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          {{ translations.global.transactions }}
          <export-transactions-button
            v-if="isCompletedStatus(details.status)"
            :filters="getFilterToSearchTransactions()"
            :filename="`update_insurance_info_details`"
            :filenameSuffixes="filenameSuffixes"
            :button-label="translations.updates.actions_label.export_transactions"/>
        </div>
      </template>
      <financial-list
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :data="transactions"
        :disabled="loading"
        :items-per-page="itemsPerPage"
        @page-changed="loadTransactionsList"
        :total="total"
        :no-items-message="translations.transactions.missing_transactions"/>
    </b-card>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import ExportTransactionsButton from '@/components/Financial/ExportTransactionsButton.vue';
import translations from '@/translations';
import { formatDate, formatDatetime } from '@/helpers/finance';

export default {
  name: 'FinanceUpdatesDetails',
  components: {
    BackButton,
    ExportTransactionsButton,
    FinancialList,
  },
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.transactions.transaction_id, 'id'),
      associateHeaderDataItem(this.translations.transactions.created_at, 'created_at'),
      associateHeaderDataItem(this.translations.transactions.client_name, 'client_name'),
      associateHeaderDataItem(this.translations.transactions.account_uuid, 'account_uuid'),
      associateHeaderDataItem(this.translations.transactions.program_uuid, 'program_uuid'),
      associateHeaderDataItem(this.translations.transactions.program_id, 'patient_id'),
      associateHeaderDataItem(this.translations.transactions.patient_name, 'member_full_name'),
      associateHeaderDataItem(this.translations.transactions.unit, 'unit'),
      associateHeaderDataItem(this.translations.transactions.therapy, 'therapy_name'),
      associateHeaderDataItem(this.translations.transactions.transaction_type, 'transaction_type'),
      associateHeaderDataItem(this.translations.transactions.value, 'transaction_value'),
    ];
  },
  data() {
    return {
      translations: translations.finance,
      requestId: Number(this.$route.params.requestId) || 0,
      loading: true,
      details: {},
      transactions: [],
      total: 0,
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  async beforeMount() {
    await this.getDetail();
    await this.loadTransactionsList();
  },
  methods: {
    formatDate,
    formatDatetime,
    async changePage(page) {
      this.currentPage = page;
      await this.loadTransactionsList();
    },
    getFilterToSearchTransactions() {
      return {
        event_date_start: formatDate(this.details.statement_start_date),
        event_date_end: formatDate(this.details.completed_at),
        client_id: this.details.client_id,
        order_by: 'id',
      };
    },
    async getDetail() {
      try {
        this.loading = true;
        this.details = await this.$store.dispatch('Financial/getRefreshTransactionDetail', this.requestId);
      } catch (err) {
        let errDetail = err;
        if (err.response?.data?.error?.detail) {
          errDetail = err.response.data.error.detail;
        }

        const errMsg = `${this.translations.updates.errors.fetch_details_error} ${errDetail}`;
        this.$noty.error(errMsg);
      } finally {
        this.loading = false;
      }
    },
    async loadTransactionsList() {
      if (!this.isCompletedStatus(this.details.status)) {
        return;
      }

      const filters = {
        event_date_start: formatDate(this.details.statement_start_date),
        event_date_end: formatDate(this.details.completed_at),
        client_id: this.details.client_id,
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        order_descending: true,
        order_by: 'id',
      };

      const apiTransactions = await this.$store.dispatch('Financial/getTransactions', filters);

      if (!apiTransactions) {
        this.total = 0;
        this.transactions = [];
      } else {
        this.total = apiTransactions.total;
        this.transactions = apiTransactions.transactions;
      }

      this.transactions.forEach(transaction => {
        transaction.created_at = formatDate(transaction.created_at);
      });
    },
    formatStatusLabel(label) {
      if (this.isCompletedStatus(label)) {
        return 'success';
      }
      return 'secondary';
    },
    isCompletedStatus(status) {
      return status?.toLowerCase() === 'completed';
    },
    formatBoolean(v) {
      return v ? 'Yes' : 'No';
    },
  },
};
</script>
